import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Module } from 'src/app/shared/models/module';

@Component({
    templateUrl: './edit-module-dialog.component.html',
    styleUrls: ['./edit-module-dialog.component.scss'],
})
export class EditModuleDialog {
    form = new FormGroup({
        key: new FormControl('', Validators.required),
        name: new FormControl('', Validators.required),
    });

    constructor(
        public dialogRef: MatDialogRef<EditModuleDialog>,
        public translate: TranslateService,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            module: Module;
        },
    ) {
        this.form.setValue({
            key: this.data?.module?.key ?? '',
            name: this.data?.module?.name ?? '',
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    confirm() {
        this.dialogRef.close(this.form.value);
    }
}
